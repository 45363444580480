import { IAvailablePlatform, IConnectedStore, IGetStoresParams } from '@app/api/store.api';
import { platformImages } from '@app/constants/storeImage';
import useDebounce from '@app/hooks/useDebounce';
import { Button, Input } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import AvailablePlatformList from './AvailablePlatformList';
import ConnectedTableStore from './ConnectedTableStore';
import {
  ConnectButton,
  ConnectStoreContainer,
  ConnectStoreContent,
  ConnectStoreInstruction,
  ConnectStoreTitleWrapper,
  Step,
  StepDescription,
  StepWrapper,
  StoreContainer,
} from './Store.style';
import useUpdateEffect from '@app/hooks/useUpdateEffect';

interface Props {
  platforms: IAvailablePlatform[];
  connectedStores: IConnectedStore[];
  fetchConnectedStores(params: IGetStoresParams): Promise<void>;
  handleChangeConnectedStores(store: IConnectedStore): void;
  totalConnectedStores?: number;
}

const StoreContent = ({
  platforms,
  connectedStores,
  fetchConnectedStores,
  handleChangeConnectedStores,
  totalConnectedStores,
}: Props) => {
  const [searchVal, setSearchVal] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState<IAvailablePlatform>();
  const [searchLoading, setSearchLoading] = useState(false);
  const debounceValue = useDebounce(searchVal, 300);

  const getPlatFormImage = (name?: string) => {
    return name ? platformImages[name] : '';
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };
  const handleChangePlatForm = (platform: IAvailablePlatform) => {
    setSelectedPlatform(platform);
  };

  useUpdateEffect(() => {
    const fetchData = async () => {
      setSearchLoading(true);
      await fetchConnectedStores({ page: 1, limit: 10, search: debounceValue });
      setSearchLoading(false);
    };
    fetchData();
  }, [debounceValue, fetchConnectedStores]);
  return (
    <StoreContainer>
      {!selectedPlatform ? (
        <>
          <Input style={{ paddingLeft: '37px' }} placeholder="Find your store..." onChange={handleChange} />
          <AvailablePlatformList availablePlatforms={platforms} onChangePlatform={handleChangePlatForm} />
          <ConnectedTableStore
            connectedStores={connectedStores}
            fetchConnectedStores={fetchConnectedStores}
            handleChangeConnectedStores={handleChangeConnectedStores}
            totalConnectedStores={totalConnectedStores}
            debounceValue={debounceValue}
            searchLoading={searchLoading}
          />
        </>
      ) : (
        <>
          <Button
            size="small"
            style={{ minWidth: '100px' }}
            onClick={() => {
              setSelectedPlatform(undefined);
            }}
          >
            Back
          </Button>
          <ConnectStoreContainer>
            <ConnectStoreContent>
              <ConnectStoreTitleWrapper>
                <span>Connect to</span>
                <img src={getPlatFormImage(selectedPlatform?.name)} alt={selectedPlatform?.name} />
              </ConnectStoreTitleWrapper>
              <div>
                <span>{`Connect Your ${selectedPlatform?.name} Shop to start dropshipping with EGFULFILL`}</span>
                <ConnectStoreInstruction>
                  <StepWrapper>
                    <Step>Step 1</Step>
                    <StepDescription>Click the connect button below and follow the instructions.</StepDescription>
                  </StepWrapper>

                  <StepWrapper>
                    <Step>Step 2</Step>
                    <StepDescription>{`Log in your ${selectedPlatform?.name} Shop account and click “Allow Access”.`}</StepDescription>
                  </StepWrapper>

                  <StepWrapper>
                    <Step>Step 3</Step>
                    <StepDescription>{`Manage and sync orders from your ${selectedPlatform?.name} Shop store to EGFULFILL`}</StepDescription>
                  </StepWrapper>
                </ConnectStoreInstruction>
              </div>

              <ConnectButton
                onClick={() => {
                  window.open(selectedPlatform?.baseApiUrl, '_self');
                }}
              >
                Connect
              </ConnectButton>
            </ConnectStoreContent>
          </ConnectStoreContainer>
        </>
      )}
    </StoreContainer>
  );
};

export default StoreContent;
