import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Button } from 'antd';
import styled from 'styled-components';
import { BaseAlert } from '../common/BaseAlert/BaseAlert';
import { BaseModal } from '../common/BaseModal/BaseModal';

export const StoreContainer = styled.div`
  padding-inline: 0px;
  align-items: center;
  @media only screen and ${media.lg} {
    padding: 13px 94px 0px 118px;
  }
`;

export const AvailablePlatformContainer = styled.div`
  min-height: 198px;
  margin-top: 47px;
  margin-bottom: 25px;
`;

export const CardItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 21px;
  border: 1px solid #dadada;
  height: 198px;
  cursor: pointer;
`;

export const PlatformImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const StatusAlert = styled(BaseAlert)`
  min-width: 107px;
  text-align: center;
  border-radius: 19px;
  .ant-alert-message {
    color: var(--black);
  }
`;

export const ConnectStoreContainer = styled.div`
  padding-top: 48px;
  display: flex;
  justify-content: center;
`;

export const ConnectStoreContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConnectStoreTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  font-size: ${FONT_SIZE.xxl};
`;

export const ConnectStoreInstruction = styled.div`
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  gap: 11px;
`;
export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const Step = styled.div`
  width: 55px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #e7ebed;
  font-size: ${FONT_SIZE.xs};
`;

export const StepDescription = styled.div`
  font-size: ${FONT_SIZE.xs};
`;

export const ConnectButton = styled(Button)`
  margin-top: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 299px;
  height: 41px;
  background-color: #e96d1f;
  font-size: ${FONT_SIZE.xs};
  color: white;
  &:hover {
    background-color: rgba(233, 109, 31, 0.8);
    color: white;
  }

  &:focus {
    background-color: #e96d1f;
    color: white;
  }
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const FieldLabel = styled.span`
  min-width: 90px;
  min-height: 50px;
  font-weight: bold;
`;

export const FieldContent = styled.span`
  word-break: break-word;
`;
