import { IConnectedStore, IGetStoresParams, IUpdateStoreParams, updateConnectedStore } from '@app/api/store.api';
import { Pagination } from '@app/api/table.api';
import { ReactComponent as SettingIcon } from '@app/assets/icons/setting.svg';
import { Input, Switch } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { BaseTable } from '../common/BaseTable/BaseTable';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { StatusAlert } from './Store.style';

interface Props {
  connectedStores: IConnectedStore[];
  fetchConnectedStores(params: IGetStoresParams): Promise<void>;
  handleChangeConnectedStores(store: IConnectedStore): void;
  totalConnectedStores?: number;
  debounceValue: string;
  searchLoading: boolean;
}

const columns: ColumnsType<IConnectedStore> = [
  {
    title: 'Shop Name',
    dataIndex: 'name',
  },
  {
    title: 'Marketplace',
    dataIndex: 'marketPlace',
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    render: (isActive: string) =>
      isActive ? (
        <StatusAlert style={{ width: 'fit-content', color: 'black', background: '#C4FFB2' }} message="Active" />
      ) : (
        <StatusAlert style={{ width: 'fit-content', color: 'black', background: '#FFB2B2' }} message="Deactivated" />
      ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    align: 'center',
  },
];

const ConnectedTableStore = ({
  connectedStores,
  fetchConnectedStores,
  handleChangeConnectedStores,
  totalConnectedStores,
  debounceValue,
  searchLoading,
}: Props) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<{
    data: IConnectedStore[];
    pagination: TablePaginationConfig;
    loading: boolean;
  }>({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });
  const [open, setOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<IConnectedStore>();
  const currentPage = useRef(1);

  const handleTableChange = (pagination: Pagination) => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    if (pagination.current && pagination.pageSize) {
      currentPage.current = pagination.current;
      fetchConnectedStores({ page: pagination.current, limit: pagination.pageSize, search: debounceValue });
    }
  };

  const handleUpdateConnectedStore = async (value: IUpdateStoreParams) => {
    if (selectedStore) {
      const newConnectedStore = await updateConnectedStore(selectedStore.uuid, {
        name: value.name,
        isActive: value.isActive,
      });
      handleChangeConnectedStores(newConnectedStore);
      setOpen(false);
    }
  };

  useEffect(() => {
    console.log(searchLoading);

    if ((connectedStores.length === 0 && !debounceValue) || searchLoading) {
      setTableData((tableData) => ({ ...tableData, loading: true }));
    } else {
      setTableData((tableData) => ({
        ...tableData,
        loading: false,
        data: connectedStores.map((item: IConnectedStore) => {
          const newItem = {
            ...item,
            actions: (
              <SettingIcon
                onClick={(e) => {
                  setOpen(true);
                  setSelectedStore(item);
                  e.stopPropagation();
                }}
              />
            ),
          };
          return newItem;
        }),
        pagination: {
          total: totalConnectedStores,
          current: currentPage.current,
          pageSize: 10,
        },
      }));
    }
  }, [connectedStores, debounceValue, searchLoading, totalConnectedStores]);

  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        isHoverRow
        onRow={(record) => ({ onClick: () => navigate(`detail/${record.uuid}`) })}
        scroll={{ x: 500 }}
        rowKey={(record) => record.uuid}
      />
      <BaseModal title="Edit Store" open={open} centered onCancel={() => setOpen(false)} footer={null} destroyOnClose>
        <BaseForm id={'detail-form'} layout="horizontal" onFinish={handleUpdateConnectedStore}>
          <BaseForm.Item label="Name" name="name" initialValue={selectedStore?.name}>
            <Input />
          </BaseForm.Item>
          <BaseForm.Item label="Status" name="isActive" valuePropName="checked" initialValue={selectedStore?.isActive}>
            <Switch defaultChecked={selectedStore?.isActive} />
          </BaseForm.Item>
        </BaseForm>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
          <BaseButton type="ghost" onClick={() => setOpen(false)}>
            Cancel
          </BaseButton>
          <BaseButton type="primary" htmlType="submit" form={'detail-form'}>
            Save
          </BaseButton>
        </div>
      </BaseModal>
    </>
  );
};

export default ConnectedTableStore;
