import { IConnectedStoreDetail } from '@app/api/store.api';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseRow } from '../common/BaseRow/BaseRow';
import FieldValue from './FieldValue';
import { StatusAlert, StoreContainer } from './Store.style';
import { formatDate } from '@app/utils/utils';

interface Props {
  connectedStoreDetail?: IConnectedStoreDetail;
}

const ConnectedStoreDetail = ({ connectedStoreDetail }: Props) => {
  return (
    <StoreContainer>
      <BaseRow gutter={[45, 20]}>
        <BaseCol
          xs={24}
          sm={12}
          lg={8}
          xl={8}
          xxl={8}
          style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
        >
          <FieldValue label="Shop Name" value={connectedStoreDetail?.name} />
          <FieldValue label="Market Place" value={connectedStoreDetail?.marketPlace} />
        </BaseCol>

        <BaseCol xs={24} sm={12} lg={8} xl={8} xxl={8}>
          <FieldValue
            label="Status"
            value={
              connectedStoreDetail ? (
                connectedStoreDetail?.isActive ? (
                  <StatusAlert
                    style={{ width: 'fit-content', color: 'black', background: '#C4FFB2' }}
                    message="Active"
                  />
                ) : (
                  <StatusAlert
                    style={{ width: 'fit-content', color: 'black', background: '#FFB2B2' }}
                    message="Deactivated"
                  />
                )
              ) : (
                ''
              )
            }
          />
          <FieldValue label="Created On" value={formatDate(connectedStoreDetail?.createdAt || '', 'internal')} />
        </BaseCol>

        <BaseCol xs={24} sm={12} lg={8} xl={8} xxl={8}>
          <FieldValue label="Received" value={connectedStoreDetail?.orderStatus.received} />
          <FieldValue label="Fulfilled" value={connectedStoreDetail?.orderStatus.fulfilled} />
          <FieldValue label="Cancelled" value={connectedStoreDetail?.orderStatus.canceled} />
        </BaseCol>
      </BaseRow>
    </StoreContainer>
  );
};

export default ConnectedStoreDetail;
