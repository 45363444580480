import React from 'react';
import { FieldContent, FieldLabel, FieldValueWrapper } from './Store.style';

interface Props {
  label: string;
  value: React.ReactNode;
}

const FieldValue = ({ label, value }: Props) => {
  return (
    <FieldValueWrapper>
      <FieldLabel>{label}</FieldLabel>
      <FieldContent>{value}</FieldContent>
    </FieldValueWrapper>
  );
};

export default FieldValue;
