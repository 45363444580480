import { httpApi } from './http.api';

export type TFormData = {
  text?: string;
  fromDate?: Date | string;
  toDate?: Date | string;
  changeType?: string;
  email?: string;
  size?: number;
  page?: number;
};

export const postHistory = async (data: TFormData) => {
  const res = await httpApi.post('api/v1/history/transaction/search', data);
  return res;
};
