import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as StyleTable from '@app/components/tables/Tables/Tables.styles';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ReactComponent as ImoprtIcon } from '@app/assets/icons/import.svg';
import { ReactComponent as ExportIcon } from '@app/assets/icons/export.svg';
import OrderModal from './workflow/ModalOrder';
import { BaseRow } from '../common/BaseRow/BaseRow';
import * as S from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { AppDate, Dates } from '@app/constants/Dates';
import { RangeValue } from 'rc-picker/lib/interface';
import { Export, getOrder, orderType, paramsOrder } from '@app/api/order.api';
import { useMounted } from '@app/hooks/useMounted';
import { Pagination } from '@app/api/table.api';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { BaseSpace } from '../common/BaseSpace/BaseSpace';
import { BaseTooltip } from '../common/BaseTooltip/BaseTooltip';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import BaseFilter, { checkedListsType, optionsType } from '../common/BaseFilter/Filter';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import { BaseAlert } from '../common/BaseAlert/BaseAlert';
import { Tracking } from './Orders.styles';
import { saveAs } from 'file-saver';
import { useResponsive } from '@app/hooks/useResponsive';
import useCurrentRole from '@app/hooks/useCurrentRole';
import BaseSelectFilter from '../common/BaseSelectFilter';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';

const getStatusBySeller = (status: string) => {
  switch (status) {
    case 'REFUND_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#665DB6' }} message={'Refund Pending'} />;
    case 'REFUNDED':
      return <BaseAlert style={{ width: 'fit-content', background: '#4A41A1' }} message={'Refunded'} />;
    case 'CONFIRM_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#41A0D6' }} message={'Confirm Pending'} />;
    case 'CONFIRMED':
      return <BaseAlert style={{ width: 'fit-content', background: '#0C8ED8' }} message={'Confirmed'} />;
    case 'SHIPPED':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Shipped'} />;
    case 'ACCEPTED':
      return <BaseAlert style={{ width: 'fit-content', background: '#EB8D2F' }} message={'Accepted'} />;
    case 'IN_TRANSIT':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'In Transit'} />;
    case 'IN_PRODUCTION':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#FFDE59' }}
          className={'statuss-in-product'}
          message={'In Production'}
        />
      );
    case 'PENDING':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#2E5C92' }}
          message={status.toLowerCase().replace('_', ' ')}
        />
      );
    case 'CANCEL_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#FD4D5A' }} message={'Cancel Pending'} />;
    case 'CANCELED':
      return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Cancel'} />;
    case 'REPLACE_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#D483B8' }} message={'Replace Pending'} />;
    case 'REPLACED':
      return <BaseAlert style={{ width: 'fit-content', background: '#D8249A' }} message={'Replaced'} />;
    case 'DELIVERED':
      return <BaseAlert style={{ width: 'fit-content' }} type="success" message={'Delivered'} />;
    case 'RETURNED':
      return <BaseAlert style={{ width: 'fit-content' }} type="error" message={'Returned'} />;
    default:
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#f3f3f3' }}
          message={status.toLowerCase().replace('_', ' ')}
          getColor="#575757"
        />
      );
  }
};

const getStatusByAdmin = (status: string) => {
  switch (status) {
    case 'REFUND_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#665DB6' }} message={'Refund Pending'} />;
    case 'REFUNDED':
      return <BaseAlert style={{ width: 'fit-content', background: '#4A41A1' }} message={'Refunded'} />;
    case 'CONFIRM_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#41A0D6' }} message={'Confirm Pending'} />;
    case 'CONFIRMED':
      return <BaseAlert style={{ width: 'fit-content', background: '#0C8ED8' }} message={'Confirmed'} />;
    case 'SHIPPED':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Shipped'} />;
    case 'ACCEPTED':
      return <BaseAlert style={{ width: 'fit-content', background: '#EB8D2F' }} message={'Accepted'} />;
    case 'IN_TRANSIT':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'In Transit'} />;
    case 'IN_PRODUCTION':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#FFDE59' }}
          className={'statuss-in-product'}
          message={'In Production'}
        />
      );
    case 'PENDING':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#2E5C92' }}
          message={status.toLowerCase().replace('_', ' ')}
        />
      );
    case 'CANCEL_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#FD4D5A' }} message={'Cancel Pending'} />;
    case 'CANCELED':
      return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Cancel'} />;
    case 'REPLACE_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#D483B8' }} message={'Replace Pending'} />;
    case 'REPLACED':
      return <BaseAlert style={{ width: 'fit-content', background: '#D8249A' }} message={'Replaced'} />;
    case 'DELIVERED':
      return <BaseAlert style={{ width: 'fit-content' }} type="success" message={'Delivered'} />;
    case 'RETURNED':
      return <BaseAlert style={{ width: 'fit-content' }} type="error" message={'Returned'} />;
    default:
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#f3f3f3' }}
          message={status.toLowerCase().replace('_', ' ')}
          getColor="#575757"
        />
      );
  }
};

const getStatusByFactory = (status: string) => {
  switch (status) {
    case 'REFUND_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#665DB6' }} message={'Refund Pending'} />;
    case 'REFUNDED':
      return <BaseAlert style={{ width: 'fit-content', background: '#4A41A1' }} message={'Refunded'} />;
    case 'CONFIRM_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#41A0D6' }} message={'Confirm Pending'} />;
    case 'CONFIRMED':
      return <BaseAlert style={{ width: 'fit-content', background: '#0C8ED8' }} message={'Confirmed'} />;
    case 'SHIPPED':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Shipped'} />;
    case 'ACCEPTED':
      return <BaseAlert style={{ width: 'fit-content', background: '#EB8D2F' }} message={'Accepted'} />;
    case 'IN_TRANSIT':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'In Transit'} />;
    case 'IN_PRODUCTION':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#FFDE59' }}
          className={'statuss-in-product'}
          message={'In Production'}
        />
      );
    case 'PENDING':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#2E5C92' }}
          message={status.toLowerCase().replace('_', ' ')}
        />
      );
    case 'CANCEL_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#FD4D5A' }} message={'Cancel Pending'} />;
    case 'CANCELED':
      return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Cancel'} />;
    case 'REPLACE_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#D483B8' }} message={'Replace Pending'} />;
    case 'REPLACED':
      return <BaseAlert style={{ width: 'fit-content', background: '#D8249A' }} message={'Replaced'} />;
    case 'DELIVERED':
      return <BaseAlert style={{ width: 'fit-content' }} type="success" message={'Delivered'} />;
    case 'RETURNED':
      return <BaseAlert style={{ width: 'fit-content' }} type="error" message={'Returned'} />;
    default:
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#f3f3f3' }}
          message={status.toLowerCase().replace('_', ' ')}
          getColor="#575757"
        />
      );
  }
};

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const columns: ColumnsType<orderType> = [
  {
    title: 'No.',
    dataIndex: 'no',
    align: 'center',
  },
  {
    title: 'Order ID',
    dataIndex: 'orderId',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => getStatusBySeller(status),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    align: 'center',
    render: (text: string) => {
      return text.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
    },
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    align: 'center',
  },
  {
    title: 'Created',
    dataIndex: 'createdDate',
  },
  {
    title: 'Tracking ID',
    dataIndex: 'trackingUrl',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    align: 'center',
  },
];

const columnsAdmin: ColumnsType<orderType> = [
  {
    title: 'No.',
    dataIndex: 'no',
    align: 'center',
  },
  {
    title: 'Order ID',
    dataIndex: 'orderId',
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    render: (text: string, record: orderType) => {
      return (
        <>
          {record.owner}
          <br></br>
          {record.ownerName}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => getStatusByAdmin(status),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    align: 'center',
    render: (text: string) => {
      return text.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
    },
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    align: 'center',
  },
  {
    title: 'Created',
    dataIndex: 'createdDate',
  },
  {
    title: 'Tracking ID',
    dataIndex: 'trackingUrl',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    align: 'center',
  },
];
const columnsFactory: ColumnsType<orderType> = [
  {
    title: 'No.',
    dataIndex: 'no',
    align: 'center',
  },
  {
    title: 'Order ID',
    dataIndex: 'orderId',
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    render: (text: string, record: orderType) => {
      return (
        <>
          {record.owner}
          <br></br>
          {record.ownerName}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => getStatusByFactory(status),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    align: 'center',
    render: (text: string) => {
      return text.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdDate',
  },
  {
    title: 'Tracking ID',
    dataIndex: 'trackingUrl',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    align: 'center',
  },
];

const StatusItems = [
  { label: 'Pending', value: 'pending' },
  { label: 'Confirm Pending', value: 'confirm_pending' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Replace Pending', value: 'replace_pending' },
  { label: 'Cancel Pending', value: 'cancel_pending' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'In Production', value: 'in_production' },
  { label: 'In Transit', value: 'in_transit' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Shipped', value: 'shipped' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Replaced', value: 'replaced' },
  { label: 'Delivered', value: 'delivered' },
];

const SourceItems = [{ label: 'Import', value: 'import' }];

const TrackingItems = [
  { label: "Seller's Label", value: "Seller's Label" },
  { label: "EG's Label", value: "EG's Label" },
  { label: 'No Label', value: 'No Label' },
];

const DesignItems = [{ label: 'No Design', value: 'No Design' }];

const options: optionsType[] = [
  {
    label: 'Status',
    values: [
      'Pending',
      'Confirm Pending',
      'Refund Pending',
      'Replace Pending',
      'Cancel Pending',
      'Confirmed',
      'In Production',
      'In Transit',
      'Accepted',
      'Shipped',
      'Canceled',
      'Refunded',
      'Replaced',
      'Delivered',
    ],
  },
  {
    label: 'Source',
    values: ['Import'],
  },
  {
    label: 'Tracking Label',
    values: ["Seller's Label", "EG's Label", 'No Label'],
  },
  {
    label: 'Design',
    values: ['No Design'],
  },
];
const optionsFactory: optionsType[] = [
  {
    label: 'Status',
    values: ['Confirmed', 'In Production', 'Shipped', 'Canceled', 'Refunded', 'Replaced', 'Delivered'],
  },
  {
    label: 'Source',
    values: ['Import'],
  },
  {
    label: 'Tracking Label',
    values: ["Seller's Label", "EG's Label", 'No Label'],
  },
  {
    label: 'Design',
    values: ['No Design'],
  },
];

function OrderLayout() {
  const { mobileOnly } = useResponsive();

  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [needLoad, setNeedLoad] = useState<boolean>(true);
  const [dates, setDates] = useState<[AppDate | null, AppDate | null]>([null, null]);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchIpt, setSearchIpt] = useState<string>('');

  const [status, setStatus] = useState<string[]>([]);
  const [source, setSource] = useState<string[]>([]);
  const [trackingLabelFilter, setTrackingLabelFilter] = useState<string[]>([]);
  const [designFilter, setDesignFilter] = useState<string>();

  const [tableData, setTableData] = useState<{
    data: orderType[];
    pagination: TablePaginationConfig;
    loading: boolean;
  }>({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const { isSeller, isFactory } = useCurrentRole();

  const { isMounted } = useMounted();
  const [columnsMr] = useState<ColumnsType<orderType>>(isSeller ? columns : isFactory ? columnsFactory : columnsAdmin);

  const fetch = useCallback(
    async (pagination: Pagination) => {
      const statusQuery = status.map((text) => text.toUpperCase());
      const sourceQuery = source.map((text) => text.toUpperCase());

      let params: paramsOrder = {
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 10,
        searchText: '',
        source: !!sourceQuery?.length ? sourceQuery : undefined,
        status: !!statusQuery?.length ? statusQuery : undefined,
        trackingLabel: !!trackingLabelFilter?.length ? trackingLabelFilter : undefined,
        design: designFilter ?? undefined,
      };

      if (valueSearch) {
        params = {
          ...params,
          searchText: valueSearch,
          // trackingId: valueSearch,
        };
      }

      if (dates[0] && dates[1]) {
        params = {
          ...params,
          fromDate: fromDate as string,
          toDate: toDate as string,
        };
      }

      setTableData((tableData) => ({ ...tableData, loading: true }));

      try {
        const res = await getOrder(params);
        if (isMounted.current) {
          setTableData((tableData) => ({
            ...tableData,
            loading: false,
            data: res.content.map((item: orderType, index: number) => {
              const newItem = {
                ...item,
                no: res.number * res.size + index + 1,
                createdDate: new Date(item.createdDate).toLocaleString(),
                totalAmount: getCurrencyPrice(formatNumberWithCommas(item.totalAmount), CurrencyTypeEnum.USD),
                trackingUrl: item.trackingUrl ? (
                  <Tracking className={item.trackingLabel ? 'has-tracking-label' : ''}>
                    <p>{item.trackingUrl}</p>
                    <BaseTooltip title="Copy this Tracking ID">
                      <BaseButton
                        size="small"
                        severity="success"
                        onClick={async () => {
                          navigator.clipboard.writeText(item.trackingUrl || '');
                        }}
                      >
                        <CopyOutlined style={{ lineHeight: 0 }} />
                      </BaseButton>
                    </BaseTooltip>
                  </Tracking>
                ) : (
                  '-'
                ),
                actions: (
                  <BaseTooltip title="View">
                    <Link to={`/orders/detail/${item.uuid}`}>
                      <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                        <EyeOutlined />
                      </BaseSpace>
                    </Link>
                  </BaseTooltip>
                ),
              };
              return newItem;
            }),
            pagination: {
              total: res.totalElements,
              current: res.number + 1,
              pageSize: params.size,
            },
          }));
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dates, fromDate, isMounted, source, status, trackingLabelFilter, designFilter, toDate, valueSearch],
  );

  useEffect(() => {
    if (needLoad) {
      fetch(initialPagination);
      setNeedLoad(false);
    }
  }, [fetch, needLoad]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleExport = () => {
    setNeedLoad(true);
    Export({
      searchText: valueSearch || '',
      source: source || '',
      status: status || [],
      trackingLabel: trackingLabelFilter,
      design: designFilter,
      fromDate: fromDate || '',
      toDate: toDate || '',
      page: 0,
      size: 10,
    }).then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'file.xlsx';

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Save the Blob as a file using FileSaver.js
      saveAs(blob, fileName);
      console.log('File downloaded successfully!');
      setNeedLoad(false);
    });
  };

  const handleModal = () => {
    setModalOpen((open) => !open);
  };

  const updateFilteredField = (field: string, value: [AppDate | null, AppDate | null] | string) => {
    if (field === 'dates') {
      setDates(value as [AppDate | null, AppDate | null]);
      if (value[0] !== null) {
        setFromDate(`${Dates.format(value[0], 'YYYY-MM-DD')}T00:00:00.000+07:00`);
      }
      if (value[1] !== null) {
        setToDate(`${Dates.format(value[1], 'YYYY-MM-DD')}T00:00:00.000+07:00`);
      }
    }
  };
  const handleSearch = () => {
    setSource([]);
    setStatus([]);
    setTrackingLabelFilter([]);
    setDesignFilter(undefined);
    setDates([null, null]);
    setNeedLoad(true);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleFilter = (options: checkedListsType) => {
    const sources = options.Source ? options.Source.map((text) => text.toUpperCase()) : [];
    const status = options.Status ? options.Status.map((text) => text.toUpperCase().replaceAll(' ', '_')) : [];
    const trackingLabel = options['Tracking Label'] ? [options['Tracking Label'][0]] : [];
    const design = options.Design ? options.Design[0] : undefined;

    setSource(sources);
    setStatus(status);
    setTrackingLabelFilter([...trackingLabel]);
    setDesignFilter(design);
    setNeedLoad(true);
    setIsOpen(false);
  };

  const handleConnectToTikTok = () => {
    window.open(process.env.REACT_APP_TIKTOK_APP_URL, '_blank');
  };

  const handleChangeStatus = (selected: string[] | unknown) => {
    const query = selected as string[];

    setStatus(query);
    setNeedLoad(true);

    // setSelectedStatusItems(query);
  };

  const handleChangeTracking = (selected: string[] | unknown) => {
    const query = selected as string[];

    setTrackingLabelFilter(query);
    setNeedLoad(true);
  };

  const handleChangeSource = (selected: string | unknown) => {
    const query = selected as string;
    const convertQuery = query === undefined ? [] : [query];

    setSource([...convertQuery]);
    setNeedLoad(true);
  };

  const handleChangeDesign = (selected: string | unknown) => {
    const query = selected as string;

    setDesignFilter(query);
    setNeedLoad(true);
  };

  const handleResetFilter = () => {
    setStatus([]);
    setSource([]);
    setDesignFilter(undefined);
    setTrackingLabelFilter([]);
    setValueSearch('');
    setSearchIpt('');

    setNeedLoad(true);
  };

  const handleInputSearch = (value?: string) => {
    setValueSearch(value ? value : '');
    setNeedLoad(true);
  };

  return (
    <div>
      <StyleTable.Card id="basic-table" padding="0">
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1.25rem' }}>
          <BaseRow style={{ gap: '0.5rem' }}>
            <NewsStyle.InputWrapper>
              <NewsStyle.SearchIcon onClick={() => handleInputSearch()} />
              <NewsStyle.Input
                placeholder={t('header.search')}
                value={searchIpt}
                onChange={(event) => {
                  const value = event.target.value;

                  if (value === '' && valueSearch) {
                    handleInputSearch('');
                  }

                  setSearchIpt(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && searchIpt) {
                    handleInputSearch(searchIpt);
                  }
                }}
              />
            </NewsStyle.InputWrapper>

            {/* <BaseFilter
              open={isOpen}
              onOpenChange={() => setIsOpen(!isOpen)}
              options={isFactory ? optionsFactory : options}
              onReset={handleSearch}
              onOk={handleFilter}
              isMultipleSelect={false}
              content={
                <S.RangePicker
                  style={{ width: '20rem', height: '3.125rem' }}
                  popupClassName="rage-picker"
                  value={dates}
                  onChange={(dates: RangeValue<AppDate>) =>
                    updateFilteredField('dates', [dates?.length ? dates[0] : null, dates?.length ? dates[1] : null])
                  }
                />
              }
            /> */}
          </BaseRow>

          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <BaseButton type="primary" onClick={handleExport}>
              <ExportIcon style={{ height: '1.25rem' }} />
              <span>Export</span>
            </BaseButton>
            <BaseButton type="primary" onClick={handleModal}>
              <ImoprtIcon style={{ height: '1.25rem' }} />
              <span>Import</span>
            </BaseButton>
            <BaseButton type="primary" onClick={handleConnectToTikTok}>
              Connect
            </BaseButton>
          </div>
        </div>

        <div style={{ padding: '1.25rem', paddingTop: 0, display: 'flex', alignItems: 'center', gap: '12px' }}>
          <div>Filter by:</div>
          <BaseSelectFilter
            width={!status.length ? '85px' : status.length > 1 ? '240px' : '200px'}
            options={StatusItems}
            selectedItems={status}
            onChange={handleChangeStatus}
            placeholder="Status"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!status?.length}
            allowClear
            autoClearSearchValue={false}
          />

          <BaseSelect
            width={!!source?.length ? 100 : 95}
            height={36}
            bgColor={{ backgroundColor: !!source.length ? 'white' : 'transparent' }}
            bgFilter={'transparent'}
            options={SourceItems}
            value={source}
            onChange={handleChangeSource}
            placeholder="Source"
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!source?.length}
            allowClear
            autoClearSearchValue={false}
          />

          <BaseSelectFilter
            width={!trackingLabelFilter.length ? '135px' : trackingLabelFilter.length > 1 ? '220px' : '160px'}
            options={TrackingItems}
            selectedItems={trackingLabelFilter}
            onChange={handleChangeTracking}
            placeholder="Tracking Label"
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!trackingLabelFilter?.length}
            allowClear
            autoClearSearchValue={false}
          />

          <BaseSelect
            width={designFilter ? 123 : 95}
            height={36}
            bgColor={{ backgroundColor: designFilter ? 'white' : 'transparent' }}
            bgFilter={'transparent'}
            options={DesignItems}
            value={designFilter}
            onChange={handleChangeDesign}
            placeholder="Design"
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!designFilter}
            allowClear
            autoClearSearchValue={false}
          />

          {(!!status?.length || !!trackingLabelFilter?.length || !!source?.length || designFilter || valueSearch) && (
            <BaseButton type="ghost" style={{ width: 110 }} onClick={handleResetFilter}>
              Clear Filter
            </BaseButton>
          )}
        </div>

        <BaseTable
          columns={columnsMr}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{ x: 1040 }}
          rowKey={(record) => record.uuid}
        />
      </StyleTable.Card>

      <OrderModal isOpen={isModalOpen} onOpenChange={handleModal} onLoad={() => setNeedLoad(true)} />
    </div>
  );
}

export default OrderLayout;
