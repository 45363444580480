import React from 'react';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { FormInput, FormInputPassword } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';

export type CreateUserFormData = {
  email: string;
  password: string;
  rePassword: string;
  fullname: string;
  role: string;
};

const ROLE_OPTIONS = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Seller', value: 'SELLER' },
  { label: 'Factory', value: 'FACTORY' },
  { label: 'Support', value: 'SUPPORT' },
];

interface CreateUserModalProps {
  title: string;
  isOpen: boolean;
  onCancel: () => void;
  onFinish: (values: CreateUserFormData) => void;
  loading?: boolean;
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({ title, isOpen, onCancel, onFinish, loading }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  return (
    <BaseModal
      title={title}
      style={{ top: 60 }}
      width={500}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <BaseForm form={form} name="CreateUser" onFinish={onFinish} validateTrigger="onBlur">
        <BaseForm.Item
          name="fullname"
          label={t('common.fullname')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <FormInput placeholder={t('common.fullname')} />
        </BaseForm.Item>

        <BaseForm.Item
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <FormInput placeholder={t('common.email')} />
        </BaseForm.Item>

        <BaseForm.Item
          label={t('common.password')}
          name="password"
          rules={[
            { required: true, message: t('common.requiredField') },
            () => ({
              validator(_, value) {
                const textErr: string[] = [];
                if (!value.match(/(?=.*[0-9])/)) {
                  textErr.push('At least one digit.');
                }
                if (!value.match(/(?=.*[a-z])/)) {
                  textErr.push('At least one lowercase letter.');
                }
                if (!value.match(/(?=.*[A-Z])/)) {
                  textErr.push('At least one uppercase letter.');
                }
                if (!value.match(/(?=.*[!#$%&?])/)) {
                  textErr.push('At least one special character from !#$%&?.');
                }
                if (!value.match(/^(?!.*\s).{1,}$/)) {
                  textErr.push('No whitespace allowed.');
                }
                if (!value.match(/.{8,}/)) {
                  textErr.push('At least 8 characters in length.');
                }
                if (textErr.length > 0) {
                  return Promise.reject(new Error(textErr.join(' ')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <FormInputPassword placeholder={t('common.password')} />
        </BaseForm.Item>

        <BaseForm.Item
          label={t('common.confirmPassword')}
          name="rePassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <FormInputPassword placeholder={t('common.confirmPassword')} />
        </BaseForm.Item>

        <BaseForm.Item label="Role" name="role" initialValue={'SELLER'}>
          <BaseSelect
            height={36}
            options={ROLE_OPTIONS.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
          />
        </BaseForm.Item>

        <BaseForm.Item>
          <BaseButton type="primary" htmlType="submit" block disabled={loading}>
            OK
          </BaseButton>
        </BaseForm.Item>
      </BaseForm>
    </BaseModal>
  );
};
