import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { formatDate, formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import BaseLayout from '@app/components/layouts/BaseLayout';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import {
  changeUserStatus,
  createNewUser,
  fetchUserDetail,
  searchUserManager,
  updateBalanceUser,
  updatePasswordUser,
  userDataSelector,
} from '@app/store/slices/userSlice';
import { TFormData } from '@app/api/user.api';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Dropdown, MenuProps } from 'antd';
import { LockOutlined, PlusOutlined, MinusOutlined, EyeOutlined } from '@ant-design/icons';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { MenuInfo } from 'rc-menu/lib/interface';
import { EditUserFormData, EditUserModal } from '@app/components/userManager/EditUserModal';
import { CreateUserFormData, CreateUserModal } from '@app/components/userManager/CreateUserModal';
import { InfoUserModal } from '@app/components/userManager/InfoUserModal';
import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import BaseSelectFilter from '@app/components/common/BaseSelectFilter';

interface DataType {
  uuid: string;
  email: string;
  name: string;
  status: string;
  balance: number;
  date: Date;
}

export const initialFormData: TFormData = {
  email: undefined,
  status: undefined,
  createdFrom: undefined,
  createdTo: undefined,
  pageSize: 10,
  pageIndex: 0,
};

const items: MenuProps['items'] = [
  {
    label: 'Change Password',
    key: '1',
    icon: <LockOutlined />,
  },
  {
    label: 'Add Money',
    key: '2',
    icon: <PlusOutlined />,
  },
  {
    label: 'Subtract Money',
    key: '3',
    icon: <MinusOutlined />,
  },
];

const STATUS_OPTIONS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Confirm', value: 'WAITING_CONFIRM' },
  { label: 'Inactive', value: 'INACTIVE' },
];

const statusColor = {
  ACTIVE: {
    backgroundColor: '#F3F3F3',
    color: '#575757',
  },
  WAITING_CONFIRM: {
    backgroundColor: '#F3F3F3',
    color: '#575757',
  },
  INACTIVE: {
    backgroundColor: '#F3F3F3',
    color: '#575757',
  },
} as const;

const UserManagerPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchIpt, setSearchIpt] = useState<string>('');
  const [statusItemId, setStatusIdItem] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [dataItem, setDataItem] = useState<DataType>();
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
  const [selectedStatusItems, setSelectedStatusItems] = useState<string[]>([]);

  const { userManager } = useAppSelector(userDataSelector);
  const { data, loading, loadingStatus } = userManager;

  const handleCreateModal = (values: CreateUserFormData) => {
    dispatch(createNewUser(values));
    setIsCreateModal(false);
  };

  const onFinish = (values: EditUserFormData) => {
    if (title === 'Change Password') {
      setModalOpen(false);
      dispatch(
        updatePasswordUser({
          id: dataItem?.uuid ?? '',
          formData: values,
        }),
      );
    } else if (title === 'Add Money') {
      setModalOpen(false);

      if (!values?.amount) return;

      dispatch(
        updateBalanceUser({
          id: dataItem?.uuid ?? '',
          formData: {
            amount: values.amount,
            type: 'ADD_MONEY',
          },
        }),
      );
    } else {
      setModalOpen(false);

      if (!values?.amount) return;

      dispatch(
        updateBalanceUser({
          id: dataItem?.uuid ?? '',
          formData: {
            amount: values.amount,
            type: 'SUBTRACT_MONEY',
          },
        }),
      );
    }
  };

  const handleStatusChange = (uuid: string, newStatus: unknown) => {
    const value = newStatus as string;
    setStatusIdItem(uuid);

    dispatch(
      changeUserStatus({
        id: uuid,
        status: value,
      }),
    );
  };

  const tableData = useMemo(() => {
    return {
      data: data?.content?.map((item, index) => {
        const backgroundColorStatus = statusColor[item.status as keyof typeof statusColor];

        return {
          no: data.number * data.size + index + 1,
          uuid: item.uuid,
          email: item.email,
          name: item.fullname,
          status: (
            <BaseSelect
              width={115}
              size="small"
              bgColor={backgroundColorStatus}
              className="status"
              value={item.status}
              defaultValue={item.status}
              onChange={(value) => handleStatusChange(item.uuid, value)}
              options={STATUS_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
              }))}
              loading={statusItemId === item.uuid && loadingStatus}
            />
          ),
          balance: item.balance,
          date: item.createdDate,
        };
      }),
      pagination: {
        total: data?.totalElements,
        current: data?.number + 1,
        pageSize: data?.size || 10,
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.content, data?.number, data?.size, data?.totalElements, loadingStatus]);

  const handleMenuClick = (e: MenuInfo, data: DataType) => {
    e.domEvent.stopPropagation();
    setModalOpen(true);
    setDataItem(data);

    if (e.key === '1') {
      setTitle('Change Password');
    } else if (e.key === '2') {
      setTitle('Add Money');
    } else {
      setTitle('Subtract Money');
    }
  };

  useEffect(() => {
    dispatch(searchUserManager(initialFormData));
  }, [dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
      width: '5%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '25%',
    },
    {
      title: 'User Name',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      align: 'center',
      width: '15%',
      render: (amount: DataType['balance']) => {
        const price = getCurrencyPrice(formatNumberWithCommas(amount || 0), CurrencyTypeEnum['USD']);
        return <span>{price}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '10%',
      render: (data: Date) => {
        const content = formatDate(data, 'internal');
        return <span>{content}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'uudi',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <Dropdown
          menu={{
            items,
            onClick: (e) => handleMenuClick(e, record),
          }}
        >
          <EyeOutlined style={{ cursor: 'pointer' }} onClick={(event) => event.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination: Pagination) => {
    dispatch(
      searchUserManager({
        ...initialFormData,
        pageIndex: pagination.current ? pagination.current - 1 : 0,
        pageSize: pagination.pageSize || 10,
      }),
    );
  };

  const handleClickItem = (record: DataType) => {
    if (!record?.uuid) return;

    dispatch(fetchUserDetail(record.uuid));
    setIsInfoModal(true);
  };

  const handleChangeStatus = (selected: string[] | unknown) => {
    const query = selected as string[];

    setSelectedStatusItems(query);

    dispatch(
      searchUserManager({
        ...initialFormData,
        search: valueSearch || undefined,
        pageIndex: 0,
        status: !!query.length ? query : undefined,
      }),
    );
  };

  const handleResetFilter = () => {
    setSelectedStatusItems([]);
    setSearchIpt('');
    setValueSearch('');

    dispatch(searchUserManager(initialFormData));
  };

  const handleSearchInput = (value?: string) => {
    setValueSearch(value ? value : '');

    dispatch(
      searchUserManager({
        ...initialFormData,
        pageIndex: 0,
        search: value ? value : undefined,
        status: !!selectedStatusItems.length ? selectedStatusItems : undefined,
      }),
    );
  };

  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.userManager')}</PageTitle>

        <div>
          <BaseCard padding={'0'} style={{ backgroundColor: 'unset' }}>
            <div
              style={{
                padding: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <NewsStyle.InputWrapper>
                <NewsStyle.SearchIcon />
                <NewsStyle.Input
                  placeholder={t('header.search')}
                  value={searchIpt}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value === '' && valueSearch) {
                      handleSearchInput('');
                    }
                    setSearchIpt(value);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter' && searchIpt) {
                      handleSearchInput(searchIpt);
                    }
                  }}
                />
              </NewsStyle.InputWrapper>

              <BaseButton style={{ minWidth: '80px' }} type="primary" onClick={() => setIsCreateModal(true)}>
                <PlusIcon style={{ height: '16px' }} />
                Add new user
              </BaseButton>
            </div>

            <div style={{ padding: '1.25rem', paddingTop: 0, display: 'flex', alignItems: 'center', gap: '12px' }}>
              <div>Filter by:</div>
              <BaseSelectFilter
                width={!selectedStatusItems.length ? '100px' : selectedStatusItems.length > 1 ? '200px' : '140px'}
                options={STATUS_OPTIONS}
                selectedItems={selectedStatusItems}
                onChange={handleChangeStatus}
                placeholder="Status"
                dropdownMatchSelectWidth={false}
                defaultActiveFirstOption={false}
                bordered={!!selectedStatusItems?.length}
                allowClear
                autoClearSearchValue={false}
              />

              {(!!selectedStatusItems?.length || valueSearch) && (
                <BaseButton type="ghost" style={{ width: 110 }} onClick={handleResetFilter}>
                  Clear Filter
                </BaseButton>
              )}
            </div>

            <BaseTable
              columns={columns}
              dataSource={tableData.data}
              pagination={tableData.pagination}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: 1040 }}
              rowKey={(record) => record.uuid}
              onRow={(record) => {
                return {
                  onClick: () => handleClickItem(record),
                };
              }}
              isHoverRow
            />
          </BaseCard>
        </div>

        {isModalOpen && <EditUserModal title={title} isOpen onCancel={() => setModalOpen(false)} onFinish={onFinish} />}

        {isCreateModal && (
          <CreateUserModal
            title="Create User"
            isOpen
            onCancel={() => setIsCreateModal(false)}
            onFinish={handleCreateModal}
          />
        )}

        {isInfoModal && <InfoUserModal title="Info User" isOpen onCancel={() => setIsInfoModal(false)} />}
      </>
    </BaseLayout>
  );
};

export default UserManagerPage;
