import { httpApi } from './http.api';

export interface IAvailablePlatform {
  id: number;
  name: string;
  baseApiUrl: string;
}

export interface IGetStoresParams {
  limit: number;
  page: number;
  search: string;
}

export interface IConnectedStoreParams {
  platform: string;
  code: string;
}

export interface IUpdateStoreParams {
  name: string;
  isActive: string;
}

export interface IConnectedStore {
  uuid: string;
  name: string;
  marketPlace: string;
  isActive: boolean;
  createdAt: Date;
}

interface IOrderStatus {
  canceled: number;
  fulfilled: number;
  received: number;
}

export interface IConnectedStoreDetail extends IConnectedStore {
  orderStatus: IOrderStatus;
}

export interface IConnectedStoreResponse {
  data: IConnectedStore[];
  hasNextPage: boolean;
  total: number;
}

export interface EditStoreFormData {
  name: string;
  isActive: boolean;
}

export const connectStore = async (params: IConnectedStoreParams) => {
  const res = (await httpApi.post('api-ex/api/v1/stores', params)) as unknown;
  return res as IConnectedStore;
};

export const getPlatforms = async () => {
  const res = (await httpApi.get<IAvailablePlatform[]>('api-ex/api/v1/stores/platforms')) as unknown;
  return res as IAvailablePlatform[];
};

export const getStores = async (params: IGetStoresParams) => {
  const res = (await httpApi.get('api-ex/api/v1/stores', { params })) as unknown;
  return res as IConnectedStoreResponse;
};

export const getStoreDetail = async (uuid: string) => {
  const res = (await httpApi.get(`api-ex/api/v1/stores/${uuid}`)) as unknown;
  return res as IConnectedStoreDetail;
};

export const updateConnectedStore = async (id: string, params: IUpdateStoreParams) => {
  const res = (await httpApi.patch(`api-ex/api/v1/stores/${id}`, params)) as unknown;
  return res as IConnectedStore;
};
