import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export interface AlertProps {
  $color?: string;
}

export const Alert = styled(AntAlert)<AlertProps>`
  &.statuss-in-product {
    .ant-alert-message {
      color: #2e5c92;
    }
  }

  color: var(--black);
  line-height: 1rem;
  font-size: ${FONT_SIZE.xs};
  border: none;
  border-radius: 16px;

  .ant-alert-message {
    color: var(--white);
    ${(props) => props.$color && `color: ${props.$color}`};
    text-transform: capitalize;
  }
`;
