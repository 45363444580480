import styled, { css } from 'styled-components';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { GitHubButton } from '@app/components/header/components/GithubButton/GitHubButton';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCollapse } from '../common/BaseCollapse/BaseCollapse';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { ReactComponent as NotificationIcon } from '@app/assets/icons/noti.svg';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }

  .ant-list-empty-text {
    .ant-empty {
      margin: 0;
    }
  }
`;

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(BaseCol)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);
`;

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(BaseCol)<ProfileColumn>`
  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--layout-body-bg-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const GHButton = styled(GitHubButton)`
  display: none;

  @media only screen and ${media.lg} {
    display: block;
  }
`;

// Custom new Head
export const DesktopHeaderStyle = styled(BaseLayout.Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);

  height: 80px;
`;

export const MobileHeaderStyle = styled(BaseLayout.Header)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: var(--box-shadow-nft-color);
`;

export const HeaderBalanceStyle = styled.p`
  color: white;
  margin-bottom: 0;
`;

export const HeaderRightStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const HeaderNotificationStyle = styled(NotificationIcon)`
  height: 28px;
  width: 28px;
  color: var(--layout-header-bg-color);
  cursor: pointer;
`;

export const HeaderCreateOrderBtn = styled(BaseButton)<{ $isFullWidth?: boolean }>`
  height: 40px;
  background-color: var(--background-primary2-color);

  ${(props) =>
    props.$isFullWidth &&
    css`
      width: 100%;
    `}
`;

export const HeaderTopUpBtn = styled(BaseButton)<{ $isMobile?: boolean }>`
  position: relative;
  display: inline-block;
  color: #ffde59;
  border: 1px solid #ffd426;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  width: 310px;
  border-radius: 24px;
  font-weight: bold;
  font-size: 18px;
  vertical-align: middle;
  text-decoration: none;
  padding: 0;

  ${(props) =>
    props.$isMobile &&
    css`
      width: 100%;
      font-size: 16px;
    `}

  &:hover .circle {
    width: 100%;
  }

  &:hover .balance {
    color: var(--primary-color);
  }

  &:hover .anticon {
    color: var(--text-main-color);
    background-color: white;
  }

  &:active,
  &:focus {
    .circle {
      width: 100%;
    }

    .balance {
      color: var(--primary-color);
    }

    .anticon {
      transition: all 0.5s cubic-bezier(0.65, 0, 0.076, 1);

      position: absolute;
      color: var(--primary-color);
      background-color: white;

      border-radius: 50%;
      width: 21px;
      height: 21px;
      font-size: 21px;
      right: -6px;
      top: -6px;
    }
  }

  /*  */
  .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    background-color: #ffd426;
    width: 90px;
    height: 36px;
    border-radius: 16px;
    line-height: 36px;
    color: var(--primary-color);

    &:hover {
      width: 100%;
    }

    ${(props) =>
      props.$isMobile &&
      css`
        width: 50px;
      `}
  }

  .limit {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    left: 30px;
    padding-right: 20px;

    margin: auto;
    color: var(--primary-color);

    ${(props) =>
      props.$isMobile &&
      css`
        left: 10px;
        padding-right: 8px;
      `}
  }

  .balance {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 100%;
    margin: 0;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    padding-right: 16px;

    position: absolute;
    top: 0;
    left: 110px;
    right: 0;
    bottom: 0;
    color: #ffd426;
    text-align: center;

    ${(props) =>
      props.$isMobile &&
      css`
        left: 55px;
      `}

    .price {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }

  .anticon {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.076, 1);

    position: absolute;
    color: #2e5c92;
    background-color: #ffd426;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    font-size: 21px;
    right: -6px;
    top: -6px;
  }
`;
