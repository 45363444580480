import {
  connectStore,
  getPlatforms,
  getStores,
  IAvailablePlatform,
  IConnectedStore,
  IConnectedStoreResponse,
  IGetStoresParams,
} from '@app/api/store.api';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import BaseLayout from '@app/components/layouts/BaseLayout';
import StoreContent from '@app/components/store/StoreContent';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const initialStoreParams = {
  page: 1,
  limit: 10,
  search: '',
};

const StorePage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [platforms, setPlatforms] = useState<IAvailablePlatform[]>([]);
  const [connectedStoresRes, setConnectedStoresRes] = useState<IConnectedStoreResponse>();

  const queryParams = new URLSearchParams(location.search);
  const platform = queryParams.get('platform');
  const code = queryParams.get('code');

  const handleChangeConnectedStores = (store: IConnectedStore) => {
    if (connectedStoresRes) {
      setConnectedStoresRes({
        ...connectedStoresRes,
        data: connectedStoresRes.data.map((connectedStore) => {
          return connectedStore.uuid === store.uuid
            ? { ...connectedStore, name: store.name, isActive: store.isActive }
            : connectedStore;
        }),
      });
    }
  };

  const fetchPlatforms = useCallback(async () => {
    const res = await getPlatforms();
    setPlatforms(res);
  }, []);

  const fetchConnectedStores = useCallback(async (params: IGetStoresParams) => {
    const res = await getStores(params);
    setConnectedStoresRes(res);
  }, []);

  const handleConnectStore = useCallback(async () => {
    if (platform && code) {
      await connectStore({ platform, code });
      fetchConnectedStores(initialStoreParams);
    }
  }, [code, fetchConnectedStores, platform]);

  useEffect(() => {
    handleConnectStore();
    fetchPlatforms();
    if (!platform && !code) {
      fetchConnectedStores(initialStoreParams);
    }
  }, [code, fetchConnectedStores, fetchPlatforms, handleConnectStore, platform]);
  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.stores')}</PageTitle>
        <StoreContent
          platforms={platforms}
          connectedStores={connectedStoresRes?.data || []}
          fetchConnectedStores={fetchConnectedStores}
          handleChangeConnectedStores={handleChangeConnectedStores}
          totalConnectedStores={connectedStoresRes?.total}
        />
      </>
    </BaseLayout>
  );
};

export default StorePage;
