import styled from 'styled-components';
import { BasePopover } from '../BasePopover/BasePopover';

export const FilterContainer = styled(BasePopover)``;
export const FilterMain = styled.div`
  padding: 0.5rem;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  .filter-cluster {
    max-width: 14rem;
    overflow-x: hidden;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
    }
    .ant-checkbox-checked::after {
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
    }
  }

  .ant-checkbox-group {
    max-height: 16rem;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    margin-left: 1rem;
  }

  .filter-group {
    .ant-checkbox-wrapper {
      .ant-checkbox + span {
        :hover {
          color: var(--ant-primary-color-hover);
        }
      }
    }
  }
`;
export const FilterFooter = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  .reset-btn:not(:disabled) {
    color: var(--ant-primary-color);
    :hover {
      color: var(--ant-primary-color-hover);
    }
  }
`;
