import React from 'react';
import type { AlertProps } from 'antd';
import * as S from './BaseAlert.styles';

type BaseAlert = {
  getColor?: string;
};
export type BaseAlertProps = AlertProps & BaseAlert;

export const BaseAlert: React.FC<BaseAlertProps> = ({ getColor, ...rest }) => {
  return <S.Alert {...rest} $color={getColor} />;
};
