import { IAvailablePlatform } from '@app/api/store.api';
import { platformImages } from '@app/constants/storeImage';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { AvailablePlatformContainer, CardItemWrapper, PlatformImageWrapper } from './Store.style';

interface Props {
  availablePlatforms: IAvailablePlatform[];
  onChangePlatform(platform: IAvailablePlatform): void;
}

const AvailablePlatformList = ({ availablePlatforms, onChangePlatform }: Props) => {
  const getPlatFormImage = (name?: string) => {
    return name ? platformImages[name] : '';
  };
  return (
    <>
      <AvailablePlatformContainer>
        <BaseRow gutter={[45, 20]}>
          {availablePlatforms.map((availablePlatform) => {
            if (availablePlatform.name === 'Local') {
              return null;
            }
            return (
              <BaseCol xs={24} sm={12} lg={8} xl={6} xxl={4} key={availablePlatform.name}>
                <CardItemWrapper
                  onClick={() => {
                    onChangePlatform(availablePlatform);
                  }}
                >
                  <PlatformImageWrapper>
                    <img width="100%" src={getPlatFormImage(availablePlatform.name)} />
                  </PlatformImageWrapper>

                  <span>Connect</span>
                </CardItemWrapper>
              </BaseCol>
            );
          })}
        </BaseRow>
      </AvailablePlatformContainer>
    </>
  );
};

export default AvailablePlatformList;
