import { getStoreDetail, IConnectedStoreDetail } from '@app/api/store.api';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import BaseLayout from '@app/components/layouts/BaseLayout';
import ConnectedStoreDetail from '@app/components/store/ConnectedStoreDetail';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ConnectedStoreDetailPage = () => {
  const { uuid } = useParams();
  const [connectedStore, setConnectedStore] = useState<IConnectedStoreDetail>();
  const fetchConnectedStoreDetail = useCallback(async () => {
    if (uuid) {
      const res = await getStoreDetail(uuid);
      setConnectedStore(res);
    }
  }, [uuid]);

  useEffect(() => {
    fetchConnectedStoreDetail();
  }, [fetchConnectedStoreDetail]);

  return (
    <BaseLayout>
      <>
        <PageTitle>Detail Connected Store</PageTitle>
        <ConnectedStoreDetail connectedStoreDetail={connectedStore} />
      </>
    </BaseLayout>
  );
};

export default ConnectedStoreDetailPage;
