import React, { ComponentProps, useRef, useState } from 'react';
import { RefSelectProps, Select } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@app/assets/icons/arrow-down.svg';

type StyledSelectProps = {
  $getWidth?: string;
};

const StyledSelect = styled(Select)<StyledSelectProps>`
  width: ${(props) => (props.$getWidth ? props.$getWidth : '160px')};
  height: 36px;
  line-height: 34px;

  .ant-select-selector {
    height: 36px;
    border-radius: 16px !important;
    font-size: 14px;

    cursor: pointer !important;
  }

  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }

  .ant-select-selection-overflow {
    top: -8px;
    height: 36px;
    line-height: 36px;
  }

  .ant-select-arrow {
    color: var(--text-main-color);
  }

  & .ant-select-selection-search {
    display: none;
  }
`;

interface OptionType {
  label: string;
  value: string;
}

export interface BaseSelectProps extends ComponentProps<typeof Select> {
  options: OptionType[];
  selectedItems: string[];
  onChange: (selected: string[] | unknown) => void;
  width?: string;
  mode?: 'multiple' | 'tags' | undefined;
  placeholder?: string;
}

const BaseSelectFilter = React.forwardRef<RefSelectProps, BaseSelectProps>(
  ({ options, selectedItems, onChange, placeholder = 'Select options', width, mode = 'multiple', ...rest }, ref) => {
    const [open, setOpen] = useState(false);
    const selectRef = useRef<any>(null);

    const handleChange = (newSelectedItems: string[] | unknown) => {
      onChange(newSelectedItems);
      setOpen(false);
    };

    const handleDropdownVisibleChange = (visible: boolean) => {
      setOpen(visible);

      if (!visible && selectRef.current) {
        selectRef.current.blur();
      }
    };

    const handleArrowClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpen((prev) => !prev);
    };

    return (
      <StyledSelect
        ref={ref || selectRef}
        mode={mode}
        maxTagCount={'responsive'}
        $getWidth={width}
        showArrow
        suffixIcon={<ArrowDownIcon style={{ transform: 'rotate(180deg', height: '6px' }} />}
        value={selectedItems}
        options={options}
        open={open}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        onChange={handleChange}
        placeholder={placeholder}
        {...rest}
      />
    );
  },
);

export default BaseSelectFilter;
